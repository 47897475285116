import * as React from "react"
import Layout from "../components/layout"
import SecondaryClouds from "../components/secondary-clouds"

import {graphql} from 'gatsby'
import { Link, useI18next} from 'gatsby-plugin-react-i18next';

const NotFoundPage = ({ data }) => {
  const {language} = useI18next();
  return (
    <Layout>
      <main className="four-oh-four-container">
        <div className="four-oh-four">
          <title>{data.fourohfour.subtitle}</title>
          <h1>404</h1>
          <h3>{data.fourohfour.subtitle}</h3>
          <p className="align-center">{data.fourohfour.body}</p>
          <Link to="/" language={language} className="btn btn--purple btn--arrow"><span className="btn-text">{data.fourohfour.btnText}</span></Link>
        </div>
      </main>
      <SecondaryClouds />
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query Four($language: String!) {
  fourohfour: datoCmsFourOhFour(locale: {eq: $language}) {
    subtitle
    body
    btnText
  }
}
`

